<template>
	<div>
		<van-nav-bar
			title="案例展示"
			left-arrow
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="detail qjc-relative">
			<template v-if="this.id == 6">
				<van-image
					width="100%"
					:src="require('@/assets/images/ai_case_img.png')"
					@load="loadWtMk"
				/>
			</template>
			<template v-if="this.id == 7">
				<van-image
					width="100%"
					:src="require('@/assets/images/jy_case_img.png')"
					@load="loadWtMk"
				/>
			</template>
			<template v-if="this.id == 8">
				<van-image
					width="100%"
					:src="require('@/assets/images/bj_case_img.png')"
					@load="loadWtMk"
				/>
			</template>
			<!-- <template v-if="this.id == 4">
				
				<van-image
					width="100%"
					:src="require('@/assets/images/jingyingtaocan_detail.png')"
					@load="loadWtMk"
				/>
			</template>
			<template v-else>
				<van-image
					width="100%"
					:src="this.id == 1?require('@/assets/images/zunxiangtaocan_detail1.png'):(this.id == 2?require('@/assets/images/jingyingtaocan_detail.png'):(this.id == 3?require('@/assets/images/tiyantaocan_detail.png'):null))"
					@load="loadWtMk"
				/>
				<van-image
					v-if="this.id == 1"
					width="100%"
					:src="require('@/assets/images/zunxiangtaocan_detail2.png')"
					lazy-load
					@load="loadWtMk"
				/>
				<van-image
					v-if="this.id == 1"
					width="100%"
					:src="require('@/assets/images/zunxiangtaocan_detail3.png')"
					lazy-load
					@load="loadWtMk"
				/>
			</template> -->
			<ul class="watermark qjc-absolute qjc-clearfix"></ul>
		</div>
	</div>
</template>

<script>
	import Vue from "vue"
	import { NavBar, Image, Lazyload } from "vant"
	Vue.use(NavBar)
	   .use(Image)
	   .use(Lazyload);
	
	export default{
		name: "caseShow",
		data (){
			return {
				id: this.$route.params.id || 0
			}
		},
		computed: {
			makerText (){
				return this.id == 1?"大數據評估尊享套餐示例":(this.id == 2?"大數據評估精英套餐示例":"大數據評估體驗套餐示例")
			}
		},
		methods: {
			loadWtMk (){//加載水印
				let height = document.querySelector('.detail').clientHeight,
					liHeight = document.querySelector('html').style.fontSize.replace('px',''),
					html = '';
				for(var i=0;i<Math.floor(height/liHeight);i++){
					for(var w=0;w<4;w++){
						html += "<li class='qjc-fl' style='width: 25%;line-height: 2rem;color: #40444d;opacity: 0.05;transform: rotateZ(-20deg);'>" + this.makerText + "</li>";
					}
				}
				document.querySelector('.watermark').innerHTML = html;
			}
		}
		
	}
</script>

<style lang="scss" scoped>
	.detail{
		overflow: hidden;
		
		.watermark{
			width: 200%;
			top: 0;
			left: -50%;
			right: 0;
			bottom: 0;
		}
	}
</style>
